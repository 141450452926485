<template>
  <div :style="computedStyle" class="a_file" v-show="!isHidden" :class="CSSClasses">
      <input type="file" ref="files" multiple @change="handleFilesUpload" :name="name" style="display: none"/>
      <div class="container_title">
        <div class="a_file__title" :style="cssTitle">{{ title }}</div>
        <div :style="cssContainterButton">
          <!-- <el-button :disabled="readonly" type="primary" size="small" @click="$refs.files.click()">{{ $locale.main.button.choose_file }}</el-button> -->
          <el-tooltip v-if="tooltip" effect="dark" :content="tooltip" placement="right">
            <el-button :type="type" :plain="plain" :icon="buttonCssIcon" size="small" :style="cssButton" :round="round" :disabled="readonly || _countFileDisabledBtn" @click="$refs.files.click()">
              <el-image v-if="buttonSrcIcon" class="_icon" fit="contain" :src="buttonSrcIcon"></el-image>
              <template v-if="text">
                {{text}}
              </template>
            </el-button>
          </el-tooltip>
          <el-button v-else :type="type" :plain="plain" :icon="buttonCssIcon" size="small" :style="cssButton" :round="round" :disabled="readonly || _countFileDisabledBtn" @click="$refs.files.click()">
            <el-image v-if="buttonSrcIcon" class="_icon" fit="contain" :src="buttonSrcIcon"></el-image>
            <template v-if="text">
              {{text}}
            </template>
          </el-button>
          <el-tooltip
            v-show="_countFileDisabledBtn"
            :content="`Максимальное количество прикрепляемых файлов: ${numberFile}`"
            :open-delay="300"
            placement="top-start"
            effect="dark"
            style="margin-left: 10px"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="container_view"
      :style="styleDirection">
        <div class="container_list custom_scrollbar" :style="styleList">
          <div class="list_items">
            <div v-for="(file, index) in files" :key="index"
            :title="file.name"
            class="file_listing"
            :class="{ 'active_file': activeFile === file }"
            @click="activeFile = file; clickPreview(file)">
              <i class="el-icon-document" :style="file.uploaded ? 'color:#32b84d' : ''"></i>
              {{ file.name }}
              <span v-show="!readonly" class="remove_file" @click.stop="removeFile(file)"><i class="el-icon-close"></i></span>
            </div>
          </div>
        </div>
        <div class="view" v-if="preview !== 'not'" v-loading="previewData.loading">
          <div v-if="previewData.type === null && !previewData.loading" class="preview_text"><span>{{ $locale.interface_editor.a_file.choose_file_for_preview }}</span></div>
          <div v-if="previewData.type === PREVIEW_ERROR && !previewData.loading" class="preview_text"><span>{{ $locale.interface_editor.a_file.preview_error }}</span></div>
          <img v-if="previewData.type === PREVIEW_IMAGE" class="preview_image" :src="previewData.source" alt=""/>
          <iframe v-if="previewData.type === PREVIEW_PDF" class="preview_pdf" :src="previewData.source" title=""></iframe>
          <iframe v-if="previewData.type === PREVIEW_DOC" class="preview_doc" :src="`https://docs.google.com/gview?url=${previewData.source}&embedded=true`" title=""></iframe>
          <!-- <iframe v-if="previewData.type === PREVIEW_DOC" class="preview_doc" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${previewData.source}`" title=""></iframe> -->
          <!-- <iframe v-if="previewData.type === PREVIEW_DOC" class="preview_doc" :src="`${this.$config.api}/documenteditor?file_path=file:///data/${previewData.guid}.${previewData.extension}`" title=""></iframe> -->
          <!-- <iframe v-if="previewData.type === PREVIEW_DOC" class="preview_doc" :src="`https://docviewer.yandex.ru/?url=${this.$config.api}/${previewData.guid}.${previewData.extension}`" title=""></iframe> -->
          <div :title="$locale.interface_editor.a_file.download_file" @click="downloadFile(activeFile)" v-if="preview !== 'not' && previewData.type !== null" class="download_icon"><i class="el-icon-download"></i></div>
        </div>
      </div>
      <el-form-item
        style="height: 14px"
        :prop="name"
        :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]">
      </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

const PREVIEW_ERROR = 'error'
const PREVIEW_IMAGE = 'image'
const PREVIEW_PDF = 'pdf'
const PREVIEW_DOC = 'doc'

export default {
  name: 'a-file',
  inject: {
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, VisibleMixin],
  props: {
    value: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    title: {
      type: String,
      description: 'Текст заголовка',
      default: 'Работа с файлами'
    },
    cssTitle: {
      type: String,
      description: 'CSS стили заголовка'
    },
    cssContainterButton: {
      type: String,
      description: 'CSS контейнера кнопки'
    },
    cssButton: {
      type: String,
      description: 'CSS стили кнопки'
    },
    tooltip: {
      description: 'Подсказка для кнопки',
      type: String
    },
    type: {
      type: String,
      description: 'Тип кнопки',
      default: 'primary',
      editor: 'Select',
      options: {
        multiple: false,
        options: [
          { id: 'primary', name: 'Primary' },
          { id: 'success', name: 'Success' },
          { id: 'info', name: 'Info' },
          { id: 'warning', name: 'Warning' },
          { id: 'danger', name: 'Danger' },
          { id: 'text', name: 'Text' }
        ],
        clearable: true
      }
    },
    plain: {
      type: Boolean,
      description: 'Светлый фон'
    },
    round: {
      type: Boolean,
      description: 'Скруглить углы'
    },
    text: {
      type: String,
      description: 'Текст кнопки',
      default: 'Выберите файлы'
    },
    listIcons: {
      type: [Object, String],
      description: 'Иконка кнопки',
      editor: 'Icon'
    },
    allowFormats: {
      type: Boolean,
      description: 'Разрешить форматы'
    },
    formats: {
      type: String,
      description: 'Разрешить/запретить форматы'
    },
    sizeFile: {
      type: Number,
      description: 'Ограничить размер файла (мб)'
    },
    numberFile: {
      type: Number,
      description: 'Количество прикрепляемых файлов',
      options: {
        tooltip: {
          show: true,
          content: 'По умолчанию ограничений нет. Пример записи: 5'
        }
      }
    },
    widthList: {
      type: String,
      description: 'Ширина списка',
      default: '200px'
    },
    heightList: {
      type: String,
      description: 'Высота списка',
      default: '130px'
    },
    preview: {
      editor: 'Select',
      description: 'Предпросмотр',
      default: 'right',
      options: {
        multiple: false,
        options: [
          { name: 'Нет', id: 'not' },
          { name: 'Сверху', id: 'top' },
          { name: 'Снизу', id: 'bottom' },
          { name: 'Слева', id: 'left' },
          { name: 'Справа', id: 'right' }
        ]
      }
    },
    isRequired: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'Обязательное' },
      default: () => {
        return {
          type: 'never',
          condition_type: 'and',
          conditions: []
        }
      }
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    isHiddenFromBlock: {
      type: Boolean,
      default: false,
      frozen: true
    }
  },
  data () {
    return {
      PREVIEW_ERROR,
      PREVIEW_IMAGE,
      PREVIEW_PDF,
      PREVIEW_DOC,
      files: [],
      activeFile: null,
      previewData: {
        type: null,
        source: null,
        loading: false
      }
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS

      if (this.width && !this.block) {
        css += ';width:' + this.width
      }

      return css
    },
    // заблокировать кнопку, если кол-во файлов больше ограничений
    _countFileDisabledBtn () {
      if (this.numberFile && this.numberFile > 0 && this.files.length >= this.numberFile) {
        return true
      }

      return false
    },
    _isRequired () {
      if (this.isHidden || this.isHiddenFromBlock || this.files.length >= 1) {
        return false
      }
      return this.checkConditions(this.isRequired)
    },
    styleDirection () {
      let css = ''
      if (this.preview === 'bottom') {
        css += ';flex-direction: column'
      }
      if (this.preview === 'top') {
        css += ';flex-direction: column-reverse'
      }
      return css
    },
    styleList () {
      let css = ''
      if (this.preview === 'left') {
        css += ';width:' + this.widthList
      } else if (this.preview === 'right') {
        css += ';width:' + this.widthList
      } else if (this.preview === 'not') {
        css += ';width: 100%'
      } else {
        css += ';width: 100%'
        css += ';height:' + this.heightList
      }
      if (this.preview === 'left') {
        css += ';order: 1'
      } else {
        css += ';order: 0'
      }
      return css
    },
    buttonCssIcon () {
      if (typeof this.listIcons === 'string') {
        // String формат (использовался ранее), при выборе компонента в редакторе интерфейсов автоматически конвертируется в object в editor-icon
        return this.listIcons
      }

      if (this.listIcons && typeof this.listIcons === 'object' && this.listIcons.type === 'cssClass') {
        return this.listIcons.value
      }
    },
    buttonSrcIcon () {
      if (this.listIcons && typeof this.listIcons === 'object' && this.listIcons.type === 'src') {
        return this.listIcons.value
      }
    }
  },
  mounted () {
    this.parseData()
    this.$emit('input', [])
  },
  watch: {
    value (value) {
      if (JSON.stringify(value) !== JSON.stringify(this.files.filter(item => !item.uploaded))) {
        this.parseData()
        this.$emit('input', [])
      }
    },
    type: {
      handler (value) {
        const plain = this.getProperties()['plain']
        // Показывать только если выбран "Тип"
        this.$set(plain, 'hidden', !value)

        this.forceUpdateSettingsPanel()
      },
      immediate: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  methods: {
    parseData () {
      if (!this.value) {
        return false
      }
      let data = this.value

      try {
        data = JSON.parse(this.value)
      } catch (e) {

      }

      if (Array.isArray(data)) {
        this.files = data.map((item) => {
          item.name = `${item.name}.${item.extension}`
          item.url = `${this.$config.api}/files/${item.directory ? item.directory + '/' : ''}${item.guid}.${item.extension}`
          item.uploaded = true
          return item
        })
      }
    },
    async downloadFile (file) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(await this.getFileBlob(file))
      link.download = file.name

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async getFileBlob (file) {
      if (!file.raw) {
        let response = await this.$http({
          method: 'get',
          url: file.url,
          responseType: 'blob'
        })
        file.raw = new Blob([response.data], { type: response.data.type })
      }

      return file.raw
    },
    async clickPreview (file) {
      console.log(file)
      if (this.preview === 'not') {
        this.downloadFile(file, file.name)
        return false
      }
      this.previewData.loading = true

      if (['docx', 'doc', 'xls', 'xlsx', 'xlsm'].includes(file.extension)) {
        this.previewData.source = file.url
        this.previewData.type = PREVIEW_DOC
        this.previewData.guid = file.guid
        this.previewData.extension = file.extension
        setTimeout(() => {
          this.previewData.loading = false
        }, 1000)
      } else {
        this.showFile(await this.getFileBlob(file))
      }
    },
    showFile (file) {
      let previewType = PREVIEW_ERROR
      if (file.type.startsWith('image/')) {
        previewType = PREVIEW_IMAGE
      } else if (file.type.startsWith('application/pdf')) {
        previewType = PREVIEW_PDF
      }
      this.previewData.type = previewType

      if (previewType === PREVIEW_ERROR) {
        this.previewData.loading = false
        return false
      }
      this.previewData.source = URL.createObjectURL(file)
      this.previewData.loading = false
    },
    handleFilesUpload (e) {
      let uploadedFiles = this.$refs.files.files || this.$refs.files.dataTransfer.files
      let showMessageLimit = false
      let showMessageAllow = false
      let showMessageSizeFile = false
      let showMessageNumberFile = false
      let arrFormats = []
      // допустмый размер файла (1мб = 1048576 Байт )
      const sizeFile = (this.sizeFile || 0) * 1048576

      if (this.formats) arrFormats = this.formats.split(', ')
      for (let i = 0; i < uploadedFiles.length; i++) {
        let typeFile = uploadedFiles[i].name.substring(uploadedFiles[i].name.lastIndexOf('.') + 1)
        // allowFormats - разрешенные форматы файлов
        if (this.allowFormats) {
          if (arrFormats.length && arrFormats.includes(typeFile)) {
            this.files.push({
              name: uploadedFiles[i].name,
              raw: uploadedFiles[i],
              uploaded: false
            })
          } else {
            showMessageAllow = true
          }
        } else {
          // формат запрещен
          if (arrFormats.length && arrFormats.includes(typeFile)) {
            showMessageLimit = true
            continue
          }
          // ограничение указано и размер файла больше допустимого
          if (sizeFile && uploadedFiles[i].size >= sizeFile) {
            showMessageSizeFile = true
            continue
          }
          this.files.push({
            name: uploadedFiles[i].name,
            raw: uploadedFiles[i],
            uploaded: false
          })
          if (this.numberFile && this.numberFile > 0 && this.files.length >= this.numberFile) {
            showMessageNumberFile = true
            break
          }
        }
      }
      if (showMessageLimit) this.$message.error(`Форматы ${this.formats} ограничены в использовании`)
      if (showMessageAllow) this.$message.error(`Для загрузки доступны только ${this.formats || '(пропишите доступные форматы)'}`)
      if (showMessageSizeFile) this.$message.error(`Размер файла ограничен до ${this.sizeFile} Мбайт`)
      if (showMessageNumberFile) this.$message.error(`Максимальное количество прикрепляемых файлов: ${this.numberFile}`)
      this.$emit('input', this.files.filter(item => !item.uploaded))
    },
    async removeFile (file) {
      if (!file.uploaded) {
        this.files = this.files.filter(item => item.name !== file.name)
      }

      if (!file.id) {
        return false
      }
      let answer = await this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
      if (answer === 'confirm') {
        this.activeFile = null
        this.files = this.files.filter(item => item.id !== file.id)
        this.$http.delete(`${this.$config.api}/registryservice/attachments/${file.id}`)
      }
    }
  }
}
</script>
<style>
  .a_file {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .a_file__title {
    font-weight: bold;
    font-size: 24px;
    /* width: 100%; */
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #1D242B;
  }
  .a_file .container_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    background: white;
  }
  .a_file .view {
    background: #6E7B87;
    box-shadow: 0 0 8px 0 rgba(232,237,250,.6), 0 2px 4px 0 rgba(232,237,250,.5);
    /* width: 75%; */
    overflow: auto;
    border-radius: 8px;
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }
  .a_file .container_view {
    display: flex;
    flex: auto;
    max-height: calc(100% - 52px);
  }
  .a_file .container_list {
    box-sizing: border-box;
    background: white;
    padding: 10px;
    flex: none;
    overflow-y: auto;
  }
  .a_file .list_items {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
  .a_file .active_file {
    background: #EEF0F1;
  }
  .a_file .active_file .remove_file {
    display: inline-block;
  }
  .a_file .file_listing {
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 3px;
    padding: 5px 15px;
    padding-left: 5px;
    flex: none;
    /* width: 84%; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .a_file .file_listing:hover{
    background: #EEF0F1;
  }
  .a_file .remove_file {
    display: none;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    background: #EEF0F1;
    padding-left: 5px;
    width: 18px;
    right: 8px;
  }
  .a_file .file_listing:hover .remove_file {
    display: inline-block;
  }
  .a_file .preview_image {
    /*width: 100%;*/
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_pdf {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      margin: auto;
  }
  .a_file .preview_doc {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      margin: auto;
  }
  .a_file .preview_text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .a_file .preview_text span {
      color: white;
      font-size: 18px;
  }
  .a_file .download_icon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
    background: rgba(37, 47, 55, 0.6);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .a_file .download_icon i {
    font-size: 28px;
    color: white;
  }
</style>
